/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';

/* Quill Text Editor for p-editor */
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

/* Demos */
@import 'assets/demo/styles/flags/flags.css';
@import 'assets/demo/styles/badges.scss';
@import 'assets/demo/styles/code.scss';

/*Styles*/
@import 'assets/layout/styles/theme/theme-light/indigo/theme.css';
@import 'assets/layout/styles/custom.scss';

/*Flags*/
@import '../node_modules/flag-icons/css/flag-icons.min.css';

#myJointPaperContainer {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* port styling */
.available-magnet {
  fill: #5da271;
}

/* element styling */
.available-cell rect {
  stroke-dasharray: 5, 2;
}

/* menu styling */
.p-menuitem-link-active {
  span {
    font-weight: 900;
    color: var(--menuitem-icon-color);
  }

  .p-menuitem-icon  {
    font-weight: 900;
    color: var(--menuitem-icon-color);
  }
}

.parent-active {
  font-weight: 900;
}

.normal-text {
  font-weight: 400;
}

.p-menuitem-icon {
  color: var(--menuitem-icon-color) !important;
}

/* tooltip styling */
.p-tooltip-info {
  min-width: 350px;
}

/* calendar styling */
.fc-timegrid-event-harness > .fc-timegrid-event {
  display: block;
  inset: auto !important;
}

/* table styling */
.p-datatable-loading-overlay {
  z-index: 100 !important;
  display: flex !important;
  background-color: rgba(255, 255, 255, 0.666) !important;
}

/* Iframe styling */
iframe {
  width: 100%;
  height: 100vh;
  border-radius: 10px;
}

/* CARD STYLING */
.pay-card {
  &__auto {
    //min-height: calc(100vh - 150px);
  }
}

/* COLOR PICKER STYLING */

.p-colorpicker-preview {
  width: 120px !important;
  height: 40px !important;
  border-radius: 0 !important;
}

.info-modal {
  color: #6366f1 !important;
  background-color: #eef2ff !important;
}

.info-modal * {
  color: inherit !important;
  background-color: inherit !important;
}

.info-button {
  color: #6366f1 !important;
  background-color: #eef2ff !important;
  padding: 0.45rem 0.75rem;
  border: none !important;
}

.info-button:hover {
  background-color: #e1e5f1 !important;
}

.p-menubar {
  background: white;
}

.no-button {
    cursor: pointer;
    transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
        color: #b0b0b0;
    }
}

